'use client';
import { gql } from '@/graphql/__generated__';

export const ENTRY_CARDS_FRAGMENT = gql(`
  fragment entryCards on EntryInterface {
    ...articleCard
    ...articleIndexCard
    ...caseStudyCard
    ...caseStudyIndexCard
    ...homeCard
    ...overviewCard
    ...serviceCard
  }  
`);
