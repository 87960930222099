import { EntryThemeProps } from '@/lib/parse/theme';
import { vars } from '@/styles';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { toStringOrNull } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import Box, { BoxProps } from '../ui/Box';
import * as styles from './EntryTheme.css';

export type ThemeProps<C extends React.ElementType = 'div'> = BoxProps<
  C,
  {
    theme?: Partial<EntryThemeProps>;
    disableHeader?: boolean;
  }
>;

export default function EntryTheme<C extends React.ElementType = 'div'>({
  children,
  theme,
  disableHeader = false,
  ...props
}: ThemeProps<C>) {
  const { headerColor, headerTextColor, pageColor, pageTextColor } = theme ?? {};

  const customHeaderBg = toStringOrNull(theme?.customHeaderColor);
  const customPageBg = toStringOrNull(theme?.customPageColor);

  if (!theme) return children;

  // If disableHeader is true, we don't to accept any header colors

  const header = customHeaderBg
    ? customHeaderBg
    : headerColor
    ? vars.palette.colors[headerColor]
    : undefined;

  const page = customPageBg ? customPageBg : pageColor ? vars.palette.colors[pageColor] : undefined;

  const bgColor = disableHeader ? page : header ?? page;
  const textColor = disableHeader ? pageTextColor : headerTextColor ?? pageTextColor;

  return (
    <Box
      textColor={textColor}
      style={{
        ...assignInlineVars({
          [styles.bg]: `${bgColor}`,
        }),
      }}
      {...mergePropsClassName(props, styles.root)}>
      {children}
    </Box>
  );
}
