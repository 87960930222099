'use client';

import { ArticleCardFragment } from '@/graphql/__generated__/graphql';
import { useFragment } from '@apollo/client';
import React from 'react';
import ArticleCard from '../ArticleCard';
import { ARTICLE_CARD_FRAGMENT } from '../EntryCards/ArticleCard';
import { ResultProps } from '../Results/Result';

export default function ArticleResult({ id }: ResultProps) {
  const { data } = useFragment({
    fragment: ARTICLE_CARD_FRAGMENT,
    fragmentName: 'articleCard',
    from: { __typename: 'article_Entry', id },
  });

  const [entry, setEntry] = React.useState<ArticleCardFragment>();

  React.useEffect(() => {
    if (data) setEntry(data as ArticleCardFragment);
  }, [data]);

  if (!entry) return null;

  return <ArticleCard data={entry} />;
}
