'use client';

import Card, { CardProps } from '@/components/Card';
import { CaseStudyCardFragment } from '@/graphql/__generated__/graphql';
import { CLIENT_CATEGORY_FRAGMENT } from '@/lib/fetch/categories';
import { createFragmentParser } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, firstNonNullable, parseUri } from '@liquorice/utils';
import { typographyClasses } from '../ui/Txt/Txt.css';
import { VideoProps } from '../VideoAsset';

export type CaseStudyCardProps = {
  data: CaseStudyCardFragment;
  titleVariant?: keyof typeof typographyClasses;
  VideoProps?: Omit<VideoProps, 'videoUrl'>;
} & Omit<CardProps, 'item'>;

const clientCategoryParser = createFragmentParser(CLIENT_CATEGORY_FRAGMENT, (data) => data);

const CaseStudyCard = ({ data, titleVariant, VideoProps, ...props }: CaseStudyCardProps) => {
  const { entryImage, externalUrl, pageTitle, uri } = data;
  const clientCategory = excludeEmptyTypedElements(data.clientCategory);
  const categories = clientCategoryParser(firstNonNullable(clientCategory));
  const href = parseUri(uri);

  return (
    <Card
      padding="sm"
      elevation={false}
      item={{
        title: pageTitle,
        titleComponent: 'h3',
        image: entryImage,
        description: categories?.title,
        videoUrl: externalUrl,
        href,
      }}
      {...props}>
      {externalUrl ? (
        <Card.Video playing muted controls={false} loop {...VideoProps} />
      ) : (
        <Card.Image ratio="VIDEO" />
      )}
      <Card.Body>
        <Card.Description variant="sm" uppercase headingFont letterSpacing="lede" />
        <Card.Title variant={titleVariant ?? 'h3'} />
      </Card.Body>
    </Card>
  );
};

export default CaseStudyCard;
