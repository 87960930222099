'use client';

import ImageAsset from '@/components/ImageAsset';
import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import VideoAsset from '@/components/VideoAsset';
import { gql } from '@/graphql/__generated__';
import { parseCallToActionMultiple } from '@/lib/parse/callToActionMultiple';
import { parseBlockTheme } from '@/lib/parse/theme';
import { useBreakpointMin } from '@/lib/utils/useBreakpoints';
import { toBoolean } from '@liquorice/utils';
import { BlockContainer } from '../../BlockContainer';
import { createBlock } from '../../createBlock';
import * as styles from './TextImageBlock.css';

const TEXT_IMAGE_BLOCK = gql(`
  fragment textImageBlock on blocks_textImage_BlockType {
    content: htmlContentDefault
    linkLabel
    callToActionMultiple {
      ...callToActionMultiple
    }
    flipAlignment
    assetSingle {
      ...ImageAsset
    }
    externalUrl
    blockTheme {
      ...blockTheme
    }
  }
`);

const TextImageBlock = createBlock(TEXT_IMAGE_BLOCK, ({ data, meta }) => {
  if (!data) return null;

  const lg = useBreakpointMin('lg');

  const { assetSingle, content } = data;
  const blockTheme = parseBlockTheme(data.blockTheme);
  const links = parseCallToActionMultiple(data.callToActionMultiple);
  const flipAlignment = toBoolean(data.flipAlignment);

  const Media = () => (
    <Grid.Col lg={6}>
      <ImageAsset data={assetSingle} />
      {data.externalUrl && (
        <VideoAsset playing loop muted controls={false} videoUrl={data.externalUrl} />
      )}
    </Grid.Col>
  );

  const Text = () => (
    <Grid.Col lg={6}>
      {content && (
        <Txt html className={styles.content}>
          {content}
        </Txt>
      )}
      <Txt
        as="p"
        variant="sm" 
        uppercase
        letterSpacing="lede" 
        headingFont
        cx={{ mT:'2xl', mB:'xs' }}
      >{data.linkLabel || ''}</Txt>
      <Flex spacing="2xs" alignItems="start">
        {links?.map((link, i) => {
          return (
            link?.href && (
              <Btn
                key={i}
                variant="text"
                startIcon="arrowRight"
                animateIcon
                animateDirection="right"
                {...link}
              />
            )
          );
        })}
      </Flex>
    </Grid.Col>
  );

  return (
    <BlockContainer blockTheme={blockTheme} meta={meta} marginY>
      <Grid rowGutter>
        {flipAlignment && lg ? (
          <>
            <Media />
            <Text />
          </>
        ) : (
          <>
            <Text />
            <Media />
          </>
        )}
      </Grid>
    </BlockContainer>
  );
});

export default TextImageBlock;
