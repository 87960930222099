'use client';

import { IMG_RATIO } from '@/styles';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import Shim, { ShimProps } from '../Shim';
import VideoBackground from './VideoBackground';
import { VideoPlayer } from './VideoPlayer';

export type VideoProps = ShimProps<
  'div',
  {
    videoUrl?: Maybe<string>;
    lockRatio?: boolean;
    controls?: boolean;
    playing?: boolean;
    loop?: boolean;
    muted?: boolean;
  }
>;
const VideoAsset = ({
  videoUrl,
  lockRatio = false,
  controls = true,
  playing = false,
  loop = false,
  muted = false,
  ...props
}: VideoProps) => {
  const videoRatio = `${IMG_RATIO['VIDEO'] * 100}%`;

  const [ratio, setRatio] = useState<string>(videoRatio);

  const handleOnReady = (player: ReactPlayer) => {
    const maybeInternalPlayer = player.getInternalPlayer();
    if (!maybeInternalPlayer) return;
    Promise.all([maybeInternalPlayer.getVideoWidth(), maybeInternalPlayer.getVideoHeight()]).then(
      function ([width, height]) {
        const ratio = `${(height / width) * 100}%`;
        setRatio(ratio);
      }
    );
  };

  if (!videoUrl) return null;

  const inner = (
    <Shim style={{ paddingTop: ratio }} {...props}>
      <VideoPlayer
        {...{
          onReady: handleOnReady,
          url: videoUrl,
          controls,
          playing,
          loop,
          muted,
        }}
      />
    </Shim>
  );

  if (lockRatio)
    return (
      <Shim ratio="video">
        <VideoBackground {...{ videoUrl, controls, playing, loop, muted }} />
      </Shim>
    );

  return inner;
};

export default VideoAsset;
