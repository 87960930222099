'use client';

import {
  ArticleEntryFragment,
  ArticleIndexEntryFragment,
  CaseStudyEntryFragment,
  CaseStudyIndexEntryFragment,
  HomeEntryFragment,
  NotFoundEntryFragment,
  OverviewEntryFragment,
  ProfileEntryFragment,
  SearchEntryFragment,
  ServiceEntryFragment,
} from '@/graphql/__generated__/graphql';
import { EntryThemeProps } from '@/lib/parse/theme';
import { Color } from '@/styles/partials/palette.css';
import { createContext, ReactNode, useContext } from 'react';

export type EntryFragments =
  | HomeEntryFragment
  | ServiceEntryFragment
  | ArticleIndexEntryFragment
  | ArticleEntryFragment
  | CaseStudyIndexEntryFragment
  | CaseStudyEntryFragment
  | OverviewEntryFragment
  | SearchEntryFragment
  | NotFoundEntryFragment;

export type NonPageEntryFragment = ProfileEntryFragment;

// export type MatrixEntryFragments =

export type AllEntryFragments = EntryFragments & NonPageEntryFragment;

export type AppContext = {
  entry?: EntryFragments;
  colorSet?: Color;
  theme?: EntryThemeProps;
};

export const appContext = createContext<AppContext | undefined>(undefined);

export const EntryProvider = ({ children, value }: { children: ReactNode; value: AppContext }) => {
  return <appContext.Provider value={value}>{children}</appContext.Provider>;
};

export const useAppContext = (): AppContext | null => {
  const store = useContext(appContext);

  if (!store) return null;

  return store;
};

export default EntryProvider;
