import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import ImageAsset, { ImageAssetOwnProps } from '../ImageAsset';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardImageProps = ImageAssetOwnProps & { hover?: boolean };

export const CardImage = ({ hover = true, ...props }: CardImageProps) => {
  const { title, image } = useCard();

  return (
    image && (
      <ImageAsset
        wrapProps={{ className: style.imageWrap }}
        data={image}
        alt={title ?? ''}
        aria-label={undefined}
        fullHeight
        {...mergePropsClassName(props, style.image({ hover }))}
      />
    )
  );
};
