/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment articlesBlock on blocks_articles_BlockType {\n    articleMultiple {\n      ...articleCard\n    }\n  }\n": types.ArticlesBlockFragmentDoc,
    "\n  fragment basicContentBlock on blocks_basicContent_BlockType {\n    content: htmlContentDefault\n    width\n    flipAlignment\n    callToAction {\n      ...callToAction\n    }\n    blockTheme {\n      ...blockTheme\n    }\n  }\n": types.BasicContentBlockFragmentDoc,
    "\n  fragment brandsBlock on blocks_brands_BlockType {\n    genericText\n    assetMultiple {\n      ...ImageAsset\n    }\n  }\n": types.BrandsBlockFragmentDoc,
    "\n  fragment callToActionBlock on blocks_callToAction_BlockType {\n    genericText\n    blockTheme {\n      ...blockTheme\n    }\n  }\n": types.CallToActionBlockFragmentDoc,
    "\n  fragment caseStudiesBlock on blocks_caseStudies_BlockType {\n    caseStudyMultiple {\n      ...caseStudyCard\n    }\n  }\n": types.CaseStudiesBlockFragmentDoc,
    "\n  fragment centeredTextBlock on blocks_textCentered_BlockType {\n    content: htmlContentDefault\n    blockTheme {\n      ...blockTheme\n    }\n  }\n": types.CenteredTextBlockFragmentDoc,
    "\n  fragment entryLinksBlock on blocks_entryLinks_BlockType {\n    heading: genericText\n    entryMultiple {\n      ...entryCards\n    }\n  }\n": types.EntryLinksBlockFragmentDoc,
    "\n  fragment galleryImage on asset_Entry {\n    width\n    assetSingle {\n     ...ImageAsset\n    }\n  }\n": types.GalleryImageFragmentDoc,
    "\n  fragment galleryVideo on video_Entry {\n    width\n    externalUrl\n  }\n": types.GalleryVideoFragmentDoc,
    "\n  fragment mediaGalleryBlock on blocks_mediaGallery_BlockType {\n    gallery {\n      ...galleryImage\n      ...galleryVideo\n    }\n    blockTheme {\n      ...blockTheme\n    }\n  }\n": types.MediaGalleryBlockFragmentDoc,
    "\n  fragment profilesBlock on blocks_profiles_BlockType {\n    genericText\n    profileMultiple {\n      ...profileCard\n    }\n  }\n": types.ProfilesBlockFragmentDoc,
    "\n  fragment serviceLinksBlock on blocks_serviceLinks_BlockType {\n    heading: genericText\n    serviceMultiple {\n      ...serviceCard\n    }\n  }\n": types.ServiceLinksBlockFragmentDoc,
    "\n  fragment statementBlock on blocks_statement_BlockType {\n    genericText\n    textAlignment\n    callToAction {\n      ...callToAction\n    }\n    blockTheme {\n      ...blockTheme\n    }\n  }\n": types.StatementBlockFragmentDoc,
    "\n  fragment subFormBlock on blocks_subForm_BlockType {\n    blockTheme {\n      ...blockTheme\n    }\n  }\n": types.SubFormBlockFragmentDoc,
    "\n  fragment testimonialBlock on blocks_testimonial_BlockType {\n    __typename\n    id\n    blockTheme {\n        ...blockTheme\n    }\n    quote: htmlContentDefault\n    author: genericText\n  }\n": types.TestimonialBlockFragmentDoc,
    "\n  fragment animation on textAnimationItem_Entry {\n    content: htmlContentSimple\n    animation {\n      ...ImageAsset\n    }\n    callToActionMultiple {\n      ...callToActionMultiple\n    }\n  }\n": types.AnimationFragmentDoc,
    "\n  fragment textAnimationBlock on blocks_textAnimation_BlockType {\n    __typename\n    id\n    textAnimation {\n      ...animation\n    }\n  }\n": types.TextAnimationBlockFragmentDoc,
    "\n  fragment textColumnsBlock on blocks_textColumns_BlockType {\n    __typename\n    id\n    columnText1\n    columnText2\n    blockTheme {\n      ...blockTheme\n    }\n  }\n": types.TextColumnsBlockFragmentDoc,
    "\n  fragment textImageBlock on blocks_textImage_BlockType {\n    content: htmlContentDefault\n    linkLabel\n    callToActionMultiple {\n      ...callToActionMultiple\n    }\n    flipAlignment\n    assetSingle {\n      ...ImageAsset\n    }\n    externalUrl\n    blockTheme {\n      ...blockTheme\n    }\n  }\n": types.TextImageBlockFragmentDoc,
    "\n  fragment touchPointsBlock on blocks_touchpoints_BlockType {\n    id\n  }\n": types.TouchPointsBlockFragmentDoc,
    "\n  fragment typeformBlock on blocks_typeform_BlockType {\n    genericText\n  }\n": types.TypeformBlockFragmentDoc,
    "\n  fragment videoHeroBlock on blocks_videoHero_BlockType {\n    __typename\n    id\n    popupVideoUrl\n    clippedVideoUrl\n  }\n": types.VideoHeroBlockFragmentDoc,
    "\n  fragment articleEntry on article_Entry {\n    sectionHandle\n    postDate @formatDateTime(format: \"j F Y\")\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    blocks {\n      ...blocks\n    }\n    articleCategory {\n      ...articleCategory\n    }\n    profileSingle {\n      ...profileCard\n    }\n    articleMultiple {\n      ...entryCards\n    }\n  }\n": types.ArticleEntryFragmentDoc,
    "\n  fragment articleIndexEntry on articleIndex_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n": types.ArticleIndexEntryFragmentDoc,
    "\n  fragment caseStudyEntry on caseStudy_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    externalUrl\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    uri\n    blocks {\n      ...blocks\n    }\n    clientCategory {\n      ...clientCategory\n    }\n    serviceCategory {\n      ...serviceCategory\n    }\n    serviceDetailCategory {\n      ...serviceDetailCategory\n    }\n    teamRoles {\n      ...teamRoles\n    }\n    callToActionMultiple {\n      ...callToActionMultiple\n    }\n    caseStudyMultiple {\n      ...entryCards\n    }\n  }\n": types.CaseStudyEntryFragmentDoc,
    "\n  fragment caseStudyIndexEntry on caseStudyIndex_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n": types.CaseStudyIndexEntryFragmentDoc,
    "\n  fragment homeEntry on home_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    heading: htmlContentSimple\n    blocks {\n      ...blocks\n    }\n  }\n": types.HomeEntryFragmentDoc,
    "\n  fragment notFoundEntry on notFound_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n": types.NotFoundEntryFragmentDoc,
    "\n  fragment overviewEntry on overview_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    blocks {\n      ...blocks\n    }\n  }\n": types.OverviewEntryFragmentDoc,
    "\n  fragment searchEntry on search_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n": types.SearchEntryFragmentDoc,
    "\n  fragment serviceEntry on service_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    blocks {\n      ...blocks\n    }\n  }\n": types.ServiceEntryFragmentDoc,
    "\n  fragment entryCards on EntryInterface {\n    ...articleCard\n    ...articleIndexCard\n    ...caseStudyCard\n    ...caseStudyIndexCard\n    ...homeCard\n    ...overviewCard\n    ...serviceCard\n  }  \n": types.EntryCardsFragmentDoc,
    "\n  fragment articleCard on article_Entry {\n    __typename\n    id\n    uri\n    pageTitle\n    postDate @formatDateTime(format: \"j F Y\")\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    articleCategory {\n      ...articleCategory\n    }\n    profileSingle {\n      ...profileCard\n    }\n    articleMultiple {\n      ... on article_Entry {\n        entryImage {\n          ...ImageAsset\n        }\n        entrySummary\n        entryTheme {\n          ...entryTheme\n        }\n        articleCategory {\n          ...articleCategory\n        }\n      }\n    }\n  }\n": types.ArticleCardFragmentDoc,
    "\n  fragment articleIndexCard on articleIndex_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n": types.ArticleIndexCardFragmentDoc,
    "\n  fragment caseStudyCard on caseStudy_Entry {\n    __typename\n    id\n    uri\n    pageTitle\n    entryImage {\n      ...ImageAsset\n    }\n    externalUrl\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    clientCategory {\n      ...clientCategory\n    }\n    serviceCategory {\n      ...serviceCategory\n    }\n  }\n": types.CaseStudyCardFragmentDoc,
    "\n  fragment caseStudyIndexCard on caseStudyIndex_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n": types.CaseStudyIndexCardFragmentDoc,
    "\n  fragment homeCard on home_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n": types.HomeCardFragmentDoc,
    "\n  fragment overviewCard on overview_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n": types.OverviewCardFragmentDoc,
    "\n  fragment profileCard on profile_Entry {\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    firstName\n    lastName\n    jobPosition\n  }\n": types.ProfileCardFragmentDoc,
    "\n  fragment serviceCard on service_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    serviceDetailCategory {\n      ...serviceDetailCategory\n    }\n    pageTitle\n  }\n": types.ServiceCardFragmentDoc,
    "\n  fragment ImageAsset on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n": types.ImageAssetFragmentDoc,
    "\n  fragment teamRoles on teamRole_Entry {\n    __typename\n    id\n    title\n    profileRoleCategory {\n      ...profileRoleCategory\n    }\n    profile {\n      ...profileCard\n    }\n  }\n": types.TeamRolesFragmentDoc,
    "\n    fragment blocks on blocks_NeoField {\n        ...videoHeroBlock\n        ...statementBlock\n        ...entryLinksBlock\n        ...serviceLinksBlock\n        ...caseStudiesBlock\n        ...articlesBlock\n        ...profilesBlock\n        ...mediaGalleryBlock\n        ...brandsBlock\n        ...basicContentBlock\n        ...textAnimationBlock\n        ...textImageBlock\n        ...centeredTextBlock\n        ...textColumnsBlock\n        ...testimonialBlock\n        ...callToActionBlock\n        ...subFormBlock\n        ...typeformBlock\n        ...touchPointsBlock\n    }\n": types.BlocksFragmentDoc,
    "\n  fragment callToActionUrl on callToAction_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionUrlFragmentDoc,
    "\n  fragment callToActionEntry on callToAction_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionEntryFragmentDoc,
    "\n  fragment callToActionCustom on callToAction_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionCustomFragmentDoc,
    "\n  fragment callToActionEmail on callToAction_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionEmailFragmentDoc,
    "\n  fragment callToActionPhone on callToAction_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionPhoneFragmentDoc,
    "\n  fragment callToAction on HyperLinkInterface {\n    ...callToActionUrl\n    ...callToActionEntry\n    ...callToActionCustom\n    ...callToActionEmail\n    ...callToActionPhone\n  }\n": types.CallToActionFragmentDoc,
    "\n  fragment callToActionMultipleUrl on callToActionMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionMultipleUrlFragmentDoc,
    "\n  fragment callToActionMultipleEntry on callToActionMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionMultipleEntryFragmentDoc,
    "\n  fragment callToActionMultipleCustom on callToActionMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionMultipleCustomFragmentDoc,
    "\n  fragment callToActionMultipleEmail on callToActionMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionMultipleEmailFragmentDoc,
    "\n  fragment callToActionMultiplePhone on callToActionMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.CallToActionMultiplePhoneFragmentDoc,
    "\n  fragment callToActionMultiple on HyperLinkInterface {\n    ...callToActionMultipleUrl\n    ...callToActionMultipleEntry\n    ...callToActionMultipleCustom\n    ...callToActionMultipleEmail\n    ...callToActionMultiplePhone\n  }\n": types.CallToActionMultipleFragmentDoc,
    "\n    fragment profileEntry on profile_Entry {\n        ...profileCard\n    }\n": types.ProfileEntryFragmentDoc,
    "\n  fragment search on EntryInterface {\n    __typename\n    id\n    sectionHandle\n    uri\n    ... on article_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on articleIndex_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on caseStudy_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on caseStudyIndex_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on home_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on overview_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on service_Entry {\n      pageTitle\n      entrySummary\n    }\n  }  \n": types.SearchFragmentDoc,
    "\n  fragment clientCategory on clientCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n": types.ClientCategoryFragmentDoc,
    "\n  fragment articleCategory on articleCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n": types.ArticleCategoryFragmentDoc,
    "\n  fragment serviceCategory on serviceCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n": types.ServiceCategoryFragmentDoc,
    "\n  fragment serviceDetailCategory on serviceDetailCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n": types.ServiceDetailCategoryFragmentDoc,
    "\n  fragment profileRoleCategory on profileRoleCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n": types.ProfileRoleCategoryFragmentDoc,
    "\n  fragment entries on EntryInterface {\n    ...homeEntry\n    ...serviceEntry\n    ...articleIndexEntry\n    ...articleEntry\n    ...caseStudyIndexEntry\n    ...caseStudyEntry\n    ...overviewEntry\n    ...profileEntry\n    ...searchEntry\n    ...notFoundEntry\n  }\n": types.EntriesFragmentDoc,
    "\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    ) {\n      ...entries\n  }\n}\n": types.EntryDocument,
    "\n  fragment globalWorkWithUs on workWithUs_GlobalSet {\n    genericText\n  }\n": types.GlobalWorkWithUsFragmentDoc,
    "\n  fragment globalJobOpenings on jobOpenings_GlobalSet {\n    callToAction {\n      ...callToAction\n    }\n  }\n": types.GlobalJobOpeningsFragmentDoc,
    "\n  fragment globalAcknowledgementOfCountry on acknowledgementOfCountry_GlobalSet {\n    genericText\n  }\n": types.GlobalAcknowledgementOfCountryFragmentDoc,
    "\n  fragment globalLocation on location_GlobalSet {\n    content: htmlContentSimple\n    callToAction {\n      ...callToAction\n    }\n  }\n": types.GlobalLocationFragmentDoc,
    "\n  fragment globalContact on contact_GlobalSet {\n    callToAction {\n      ...callToAction\n    }\n  }\n": types.GlobalContactFragmentDoc,
    "\n  fragment globals on GlobalSetInterface {\n    ...globalWorkWithUs\n    ...globalJobOpenings\n    ...globalAcknowledgementOfCountry\n    ...globalLocation\n    ...globalContact\n  }\n": types.GlobalsFragmentDoc,
    "\n  query Globals {\n    globalSets {\n      ...globals\n    }\n  }\n": types.GlobalsDocument,
    "\n  query Nav($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      __typename\n      id\n      uri\n      url\n      level\n      title\n      newWindow\n      typeLabel\n      classes\n      parent {\n        id\n      }\n      element {\n        uri\n        language\n      }\n      ... on servicesNavigation_Node {        \n        colorSwatches {\n          label\n        }\n      }\n      ... on coreNavigation_Node {        \n        colorSwatches {\n          label\n        }\n      }\n    }\n  }\n": types.NavDocument,
    "\n  query commonLinks($site: [String] = \"default\") {\n    home: entry(site: $site, uri: \"__home__\") {\n      id\n      uri\n      title\n    }\n    articleIndex: entry(site: $site, section: \"articleIndex\") {\n      id\n      uri\n      title\n    }\n    caseStudyIndex: entry(site: $site, section: \"caseStudyIndex\") {\n      id\n      uri\n      title\n    }\n    serviceIndex: entry(site: $site, section: \"articleIndex\") {\n      id\n      uri\n      title\n    }\n  }\n": types.CommonLinksDocument,
    "\n  fragment entryTheme on entryTheme_Entry {\n    pageColor {\n      label\n    }\n    customPageColor\n    pageTextColor {\n      label\n    }\n    headerColor {\n      label\n    }\n    customHeaderColor\n    headerTextColor {\n      label\n    }\n  }\n": types.EntryThemeFragmentDoc,
    "\n  fragment blockTheme on blockTheme_Entry {\n    blockColor {\n      label\n    }\n    customBlockColor\n    blockTextColor {\n      label\n    }\n  }\n": types.BlockThemeFragmentDoc,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articlesBlock on blocks_articles_BlockType {\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"): (typeof documents)["\n  fragment articlesBlock on blocks_articles_BlockType {\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment basicContentBlock on blocks_basicContent_BlockType {\n    content: htmlContentDefault\n    width\n    flipAlignment\n    callToAction {\n      ...callToAction\n    }\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"): (typeof documents)["\n  fragment basicContentBlock on blocks_basicContent_BlockType {\n    content: htmlContentDefault\n    width\n    flipAlignment\n    callToAction {\n      ...callToAction\n    }\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment brandsBlock on blocks_brands_BlockType {\n    genericText\n    assetMultiple {\n      ...ImageAsset\n    }\n  }\n"): (typeof documents)["\n  fragment brandsBlock on blocks_brands_BlockType {\n    genericText\n    assetMultiple {\n      ...ImageAsset\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionBlock on blocks_callToAction_BlockType {\n    genericText\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"): (typeof documents)["\n  fragment callToActionBlock on blocks_callToAction_BlockType {\n    genericText\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment caseStudiesBlock on blocks_caseStudies_BlockType {\n    caseStudyMultiple {\n      ...caseStudyCard\n    }\n  }\n"): (typeof documents)["\n  fragment caseStudiesBlock on blocks_caseStudies_BlockType {\n    caseStudyMultiple {\n      ...caseStudyCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment centeredTextBlock on blocks_textCentered_BlockType {\n    content: htmlContentDefault\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"): (typeof documents)["\n  fragment centeredTextBlock on blocks_textCentered_BlockType {\n    content: htmlContentDefault\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryLinksBlock on blocks_entryLinks_BlockType {\n    heading: genericText\n    entryMultiple {\n      ...entryCards\n    }\n  }\n"): (typeof documents)["\n  fragment entryLinksBlock on blocks_entryLinks_BlockType {\n    heading: genericText\n    entryMultiple {\n      ...entryCards\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment galleryImage on asset_Entry {\n    width\n    assetSingle {\n     ...ImageAsset\n    }\n  }\n"): (typeof documents)["\n  fragment galleryImage on asset_Entry {\n    width\n    assetSingle {\n     ...ImageAsset\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment galleryVideo on video_Entry {\n    width\n    externalUrl\n  }\n"): (typeof documents)["\n  fragment galleryVideo on video_Entry {\n    width\n    externalUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment mediaGalleryBlock on blocks_mediaGallery_BlockType {\n    gallery {\n      ...galleryImage\n      ...galleryVideo\n    }\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"): (typeof documents)["\n  fragment mediaGalleryBlock on blocks_mediaGallery_BlockType {\n    gallery {\n      ...galleryImage\n      ...galleryVideo\n    }\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment profilesBlock on blocks_profiles_BlockType {\n    genericText\n    profileMultiple {\n      ...profileCard\n    }\n  }\n"): (typeof documents)["\n  fragment profilesBlock on blocks_profiles_BlockType {\n    genericText\n    profileMultiple {\n      ...profileCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment serviceLinksBlock on blocks_serviceLinks_BlockType {\n    heading: genericText\n    serviceMultiple {\n      ...serviceCard\n    }\n  }\n"): (typeof documents)["\n  fragment serviceLinksBlock on blocks_serviceLinks_BlockType {\n    heading: genericText\n    serviceMultiple {\n      ...serviceCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment statementBlock on blocks_statement_BlockType {\n    genericText\n    textAlignment\n    callToAction {\n      ...callToAction\n    }\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"): (typeof documents)["\n  fragment statementBlock on blocks_statement_BlockType {\n    genericText\n    textAlignment\n    callToAction {\n      ...callToAction\n    }\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment subFormBlock on blocks_subForm_BlockType {\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"): (typeof documents)["\n  fragment subFormBlock on blocks_subForm_BlockType {\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment testimonialBlock on blocks_testimonial_BlockType {\n    __typename\n    id\n    blockTheme {\n        ...blockTheme\n    }\n    quote: htmlContentDefault\n    author: genericText\n  }\n"): (typeof documents)["\n  fragment testimonialBlock on blocks_testimonial_BlockType {\n    __typename\n    id\n    blockTheme {\n        ...blockTheme\n    }\n    quote: htmlContentDefault\n    author: genericText\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment animation on textAnimationItem_Entry {\n    content: htmlContentSimple\n    animation {\n      ...ImageAsset\n    }\n    callToActionMultiple {\n      ...callToActionMultiple\n    }\n  }\n"): (typeof documents)["\n  fragment animation on textAnimationItem_Entry {\n    content: htmlContentSimple\n    animation {\n      ...ImageAsset\n    }\n    callToActionMultiple {\n      ...callToActionMultiple\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment textAnimationBlock on blocks_textAnimation_BlockType {\n    __typename\n    id\n    textAnimation {\n      ...animation\n    }\n  }\n"): (typeof documents)["\n  fragment textAnimationBlock on blocks_textAnimation_BlockType {\n    __typename\n    id\n    textAnimation {\n      ...animation\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment textColumnsBlock on blocks_textColumns_BlockType {\n    __typename\n    id\n    columnText1\n    columnText2\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"): (typeof documents)["\n  fragment textColumnsBlock on blocks_textColumns_BlockType {\n    __typename\n    id\n    columnText1\n    columnText2\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment textImageBlock on blocks_textImage_BlockType {\n    content: htmlContentDefault\n    linkLabel\n    callToActionMultiple {\n      ...callToActionMultiple\n    }\n    flipAlignment\n    assetSingle {\n      ...ImageAsset\n    }\n    externalUrl\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"): (typeof documents)["\n  fragment textImageBlock on blocks_textImage_BlockType {\n    content: htmlContentDefault\n    linkLabel\n    callToActionMultiple {\n      ...callToActionMultiple\n    }\n    flipAlignment\n    assetSingle {\n      ...ImageAsset\n    }\n    externalUrl\n    blockTheme {\n      ...blockTheme\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment touchPointsBlock on blocks_touchpoints_BlockType {\n    id\n  }\n"): (typeof documents)["\n  fragment touchPointsBlock on blocks_touchpoints_BlockType {\n    id\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment typeformBlock on blocks_typeform_BlockType {\n    genericText\n  }\n"): (typeof documents)["\n  fragment typeformBlock on blocks_typeform_BlockType {\n    genericText\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment videoHeroBlock on blocks_videoHero_BlockType {\n    __typename\n    id\n    popupVideoUrl\n    clippedVideoUrl\n  }\n"): (typeof documents)["\n  fragment videoHeroBlock on blocks_videoHero_BlockType {\n    __typename\n    id\n    popupVideoUrl\n    clippedVideoUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleEntry on article_Entry {\n    sectionHandle\n    postDate @formatDateTime(format: \"j F Y\")\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    blocks {\n      ...blocks\n    }\n    articleCategory {\n      ...articleCategory\n    }\n    profileSingle {\n      ...profileCard\n    }\n    articleMultiple {\n      ...entryCards\n    }\n  }\n"): (typeof documents)["\n  fragment articleEntry on article_Entry {\n    sectionHandle\n    postDate @formatDateTime(format: \"j F Y\")\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    blocks {\n      ...blocks\n    }\n    articleCategory {\n      ...articleCategory\n    }\n    profileSingle {\n      ...profileCard\n    }\n    articleMultiple {\n      ...entryCards\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleIndexEntry on articleIndex_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"): (typeof documents)["\n  fragment articleIndexEntry on articleIndex_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment caseStudyEntry on caseStudy_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    externalUrl\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    uri\n    blocks {\n      ...blocks\n    }\n    clientCategory {\n      ...clientCategory\n    }\n    serviceCategory {\n      ...serviceCategory\n    }\n    serviceDetailCategory {\n      ...serviceDetailCategory\n    }\n    teamRoles {\n      ...teamRoles\n    }\n    callToActionMultiple {\n      ...callToActionMultiple\n    }\n    caseStudyMultiple {\n      ...entryCards\n    }\n  }\n"): (typeof documents)["\n  fragment caseStudyEntry on caseStudy_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    externalUrl\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    uri\n    blocks {\n      ...blocks\n    }\n    clientCategory {\n      ...clientCategory\n    }\n    serviceCategory {\n      ...serviceCategory\n    }\n    serviceDetailCategory {\n      ...serviceDetailCategory\n    }\n    teamRoles {\n      ...teamRoles\n    }\n    callToActionMultiple {\n      ...callToActionMultiple\n    }\n    caseStudyMultiple {\n      ...entryCards\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment caseStudyIndexEntry on caseStudyIndex_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"): (typeof documents)["\n  fragment caseStudyIndexEntry on caseStudyIndex_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment homeEntry on home_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    heading: htmlContentSimple\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment homeEntry on home_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    heading: htmlContentSimple\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment notFoundEntry on notFound_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"): (typeof documents)["\n  fragment notFoundEntry on notFound_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment overviewEntry on overview_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment overviewEntry on overview_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment searchEntry on search_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"): (typeof documents)["\n  fragment searchEntry on search_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment serviceEntry on service_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment serviceEntry on service_Entry {\n    sectionHandle\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryCards on EntryInterface {\n    ...articleCard\n    ...articleIndexCard\n    ...caseStudyCard\n    ...caseStudyIndexCard\n    ...homeCard\n    ...overviewCard\n    ...serviceCard\n  }  \n"): (typeof documents)["\n  fragment entryCards on EntryInterface {\n    ...articleCard\n    ...articleIndexCard\n    ...caseStudyCard\n    ...caseStudyIndexCard\n    ...homeCard\n    ...overviewCard\n    ...serviceCard\n  }  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleCard on article_Entry {\n    __typename\n    id\n    uri\n    pageTitle\n    postDate @formatDateTime(format: \"j F Y\")\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    articleCategory {\n      ...articleCategory\n    }\n    profileSingle {\n      ...profileCard\n    }\n    articleMultiple {\n      ... on article_Entry {\n        entryImage {\n          ...ImageAsset\n        }\n        entrySummary\n        entryTheme {\n          ...entryTheme\n        }\n        articleCategory {\n          ...articleCategory\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment articleCard on article_Entry {\n    __typename\n    id\n    uri\n    pageTitle\n    postDate @formatDateTime(format: \"j F Y\")\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    articleCategory {\n      ...articleCategory\n    }\n    profileSingle {\n      ...profileCard\n    }\n    articleMultiple {\n      ... on article_Entry {\n        entryImage {\n          ...ImageAsset\n        }\n        entrySummary\n        entryTheme {\n          ...entryTheme\n        }\n        articleCategory {\n          ...articleCategory\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleIndexCard on articleIndex_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"): (typeof documents)["\n  fragment articleIndexCard on articleIndex_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment caseStudyCard on caseStudy_Entry {\n    __typename\n    id\n    uri\n    pageTitle\n    entryImage {\n      ...ImageAsset\n    }\n    externalUrl\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    clientCategory {\n      ...clientCategory\n    }\n    serviceCategory {\n      ...serviceCategory\n    }\n  }\n"): (typeof documents)["\n  fragment caseStudyCard on caseStudy_Entry {\n    __typename\n    id\n    uri\n    pageTitle\n    entryImage {\n      ...ImageAsset\n    }\n    externalUrl\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    clientCategory {\n      ...clientCategory\n    }\n    serviceCategory {\n      ...serviceCategory\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment caseStudyIndexCard on caseStudyIndex_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"): (typeof documents)["\n  fragment caseStudyIndexCard on caseStudyIndex_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment homeCard on home_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"): (typeof documents)["\n  fragment homeCard on home_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment overviewCard on overview_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"): (typeof documents)["\n  fragment overviewCard on overview_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    pageTitle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment profileCard on profile_Entry {\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    firstName\n    lastName\n    jobPosition\n  }\n"): (typeof documents)["\n  fragment profileCard on profile_Entry {\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    firstName\n    lastName\n    jobPosition\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment serviceCard on service_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    serviceDetailCategory {\n      ...serviceDetailCategory\n    }\n    pageTitle\n  }\n"): (typeof documents)["\n  fragment serviceCard on service_Entry {\n    uri\n    entryImage {\n      ...ImageAsset\n    }\n    entrySummary\n    entryTheme {\n      ...entryTheme\n    }\n    serviceDetailCategory {\n      ...serviceDetailCategory\n    }\n    pageTitle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ImageAsset on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n"): (typeof documents)["\n  fragment ImageAsset on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment teamRoles on teamRole_Entry {\n    __typename\n    id\n    title\n    profileRoleCategory {\n      ...profileRoleCategory\n    }\n    profile {\n      ...profileCard\n    }\n  }\n"): (typeof documents)["\n  fragment teamRoles on teamRole_Entry {\n    __typename\n    id\n    title\n    profileRoleCategory {\n      ...profileRoleCategory\n    }\n    profile {\n      ...profileCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment blocks on blocks_NeoField {\n        ...videoHeroBlock\n        ...statementBlock\n        ...entryLinksBlock\n        ...serviceLinksBlock\n        ...caseStudiesBlock\n        ...articlesBlock\n        ...profilesBlock\n        ...mediaGalleryBlock\n        ...brandsBlock\n        ...basicContentBlock\n        ...textAnimationBlock\n        ...textImageBlock\n        ...centeredTextBlock\n        ...textColumnsBlock\n        ...testimonialBlock\n        ...callToActionBlock\n        ...subFormBlock\n        ...typeformBlock\n        ...touchPointsBlock\n    }\n"): (typeof documents)["\n    fragment blocks on blocks_NeoField {\n        ...videoHeroBlock\n        ...statementBlock\n        ...entryLinksBlock\n        ...serviceLinksBlock\n        ...caseStudiesBlock\n        ...articlesBlock\n        ...profilesBlock\n        ...mediaGalleryBlock\n        ...brandsBlock\n        ...basicContentBlock\n        ...textAnimationBlock\n        ...textImageBlock\n        ...centeredTextBlock\n        ...textColumnsBlock\n        ...testimonialBlock\n        ...callToActionBlock\n        ...subFormBlock\n        ...typeformBlock\n        ...touchPointsBlock\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionUrl on callToAction_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionUrl on callToAction_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionEntry on callToAction_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionEntry on callToAction_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionCustom on callToAction_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionCustom on callToAction_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionEmail on callToAction_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionEmail on callToAction_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionPhone on callToAction_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionPhone on callToAction_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToAction on HyperLinkInterface {\n    ...callToActionUrl\n    ...callToActionEntry\n    ...callToActionCustom\n    ...callToActionEmail\n    ...callToActionPhone\n  }\n"): (typeof documents)["\n  fragment callToAction on HyperLinkInterface {\n    ...callToActionUrl\n    ...callToActionEntry\n    ...callToActionCustom\n    ...callToActionEmail\n    ...callToActionPhone\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionMultipleUrl on callToActionMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionMultipleUrl on callToActionMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionMultipleEntry on callToActionMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionMultipleEntry on callToActionMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionMultipleCustom on callToActionMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionMultipleCustom on callToActionMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionMultipleEmail on callToActionMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionMultipleEmail on callToActionMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionMultiplePhone on callToActionMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment callToActionMultiplePhone on callToActionMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment callToActionMultiple on HyperLinkInterface {\n    ...callToActionMultipleUrl\n    ...callToActionMultipleEntry\n    ...callToActionMultipleCustom\n    ...callToActionMultipleEmail\n    ...callToActionMultiplePhone\n  }\n"): (typeof documents)["\n  fragment callToActionMultiple on HyperLinkInterface {\n    ...callToActionMultipleUrl\n    ...callToActionMultipleEntry\n    ...callToActionMultipleCustom\n    ...callToActionMultipleEmail\n    ...callToActionMultiplePhone\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment profileEntry on profile_Entry {\n        ...profileCard\n    }\n"): (typeof documents)["\n    fragment profileEntry on profile_Entry {\n        ...profileCard\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment search on EntryInterface {\n    __typename\n    id\n    sectionHandle\n    uri\n    ... on article_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on articleIndex_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on caseStudy_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on caseStudyIndex_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on home_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on overview_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on service_Entry {\n      pageTitle\n      entrySummary\n    }\n  }  \n"): (typeof documents)["\n  fragment search on EntryInterface {\n    __typename\n    id\n    sectionHandle\n    uri\n    ... on article_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on articleIndex_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on caseStudy_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on caseStudyIndex_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on home_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on overview_Entry {\n      pageTitle\n      entrySummary\n    }\n    ... on service_Entry {\n      pageTitle\n      entrySummary\n    }\n  }  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment clientCategory on clientCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"): (typeof documents)["\n  fragment clientCategory on clientCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleCategory on articleCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"): (typeof documents)["\n  fragment articleCategory on articleCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment serviceCategory on serviceCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"): (typeof documents)["\n  fragment serviceCategory on serviceCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment serviceDetailCategory on serviceDetailCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"): (typeof documents)["\n  fragment serviceDetailCategory on serviceDetailCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment profileRoleCategory on profileRoleCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"): (typeof documents)["\n  fragment profileRoleCategory on profileRoleCategory_Category {\n    __typename\n    id\n    uri\n    title\n    groupHandle\n    enabled\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entries on EntryInterface {\n    ...homeEntry\n    ...serviceEntry\n    ...articleIndexEntry\n    ...articleEntry\n    ...caseStudyIndexEntry\n    ...caseStudyEntry\n    ...overviewEntry\n    ...profileEntry\n    ...searchEntry\n    ...notFoundEntry\n  }\n"): (typeof documents)["\n  fragment entries on EntryInterface {\n    ...homeEntry\n    ...serviceEntry\n    ...articleIndexEntry\n    ...articleEntry\n    ...caseStudyIndexEntry\n    ...caseStudyEntry\n    ...overviewEntry\n    ...profileEntry\n    ...searchEntry\n    ...notFoundEntry\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    ) {\n      ...entries\n  }\n}\n"): (typeof documents)["\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    ) {\n      ...entries\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalWorkWithUs on workWithUs_GlobalSet {\n    genericText\n  }\n"): (typeof documents)["\n  fragment globalWorkWithUs on workWithUs_GlobalSet {\n    genericText\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalJobOpenings on jobOpenings_GlobalSet {\n    callToAction {\n      ...callToAction\n    }\n  }\n"): (typeof documents)["\n  fragment globalJobOpenings on jobOpenings_GlobalSet {\n    callToAction {\n      ...callToAction\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalAcknowledgementOfCountry on acknowledgementOfCountry_GlobalSet {\n    genericText\n  }\n"): (typeof documents)["\n  fragment globalAcknowledgementOfCountry on acknowledgementOfCountry_GlobalSet {\n    genericText\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalLocation on location_GlobalSet {\n    content: htmlContentSimple\n    callToAction {\n      ...callToAction\n    }\n  }\n"): (typeof documents)["\n  fragment globalLocation on location_GlobalSet {\n    content: htmlContentSimple\n    callToAction {\n      ...callToAction\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalContact on contact_GlobalSet {\n    callToAction {\n      ...callToAction\n    }\n  }\n"): (typeof documents)["\n  fragment globalContact on contact_GlobalSet {\n    callToAction {\n      ...callToAction\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globals on GlobalSetInterface {\n    ...globalWorkWithUs\n    ...globalJobOpenings\n    ...globalAcknowledgementOfCountry\n    ...globalLocation\n    ...globalContact\n  }\n"): (typeof documents)["\n  fragment globals on GlobalSetInterface {\n    ...globalWorkWithUs\n    ...globalJobOpenings\n    ...globalAcknowledgementOfCountry\n    ...globalLocation\n    ...globalContact\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Globals {\n    globalSets {\n      ...globals\n    }\n  }\n"): (typeof documents)["\n  query Globals {\n    globalSets {\n      ...globals\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Nav($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      __typename\n      id\n      uri\n      url\n      level\n      title\n      newWindow\n      typeLabel\n      classes\n      parent {\n        id\n      }\n      element {\n        uri\n        language\n      }\n      ... on servicesNavigation_Node {        \n        colorSwatches {\n          label\n        }\n      }\n      ... on coreNavigation_Node {        \n        colorSwatches {\n          label\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Nav($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      __typename\n      id\n      uri\n      url\n      level\n      title\n      newWindow\n      typeLabel\n      classes\n      parent {\n        id\n      }\n      element {\n        uri\n        language\n      }\n      ... on servicesNavigation_Node {        \n        colorSwatches {\n          label\n        }\n      }\n      ... on coreNavigation_Node {        \n        colorSwatches {\n          label\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query commonLinks($site: [String] = \"default\") {\n    home: entry(site: $site, uri: \"__home__\") {\n      id\n      uri\n      title\n    }\n    articleIndex: entry(site: $site, section: \"articleIndex\") {\n      id\n      uri\n      title\n    }\n    caseStudyIndex: entry(site: $site, section: \"caseStudyIndex\") {\n      id\n      uri\n      title\n    }\n    serviceIndex: entry(site: $site, section: \"articleIndex\") {\n      id\n      uri\n      title\n    }\n  }\n"): (typeof documents)["\n  query commonLinks($site: [String] = \"default\") {\n    home: entry(site: $site, uri: \"__home__\") {\n      id\n      uri\n      title\n    }\n    articleIndex: entry(site: $site, section: \"articleIndex\") {\n      id\n      uri\n      title\n    }\n    caseStudyIndex: entry(site: $site, section: \"caseStudyIndex\") {\n      id\n      uri\n      title\n    }\n    serviceIndex: entry(site: $site, section: \"articleIndex\") {\n      id\n      uri\n      title\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryTheme on entryTheme_Entry {\n    pageColor {\n      label\n    }\n    customPageColor\n    pageTextColor {\n      label\n    }\n    headerColor {\n      label\n    }\n    customHeaderColor\n    headerTextColor {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment entryTheme on entryTheme_Entry {\n    pageColor {\n      label\n    }\n    customPageColor\n    pageTextColor {\n      label\n    }\n    headerColor {\n      label\n    }\n    customHeaderColor\n    headerTextColor {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment blockTheme on blockTheme_Entry {\n    blockColor {\n      label\n    }\n    customBlockColor\n    blockTextColor {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment blockTheme on blockTheme_Entry {\n    blockColor {\n      label\n    }\n    customBlockColor\n    blockTextColor {\n      label\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;