import { gql } from '@/graphql/__generated__';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import CaseStudyCard, { CaseStudyCardProps } from '../CaseStudyCard';
import { ListCardRoot } from '../ListCard';

export const CASE_STUDY_CARD_FRAGMENT = gql(`
  fragment caseStudyCard on caseStudy_Entry {
    __typename
    id
    uri
    pageTitle
    entryImage {
      ...ImageAsset
    }
    externalUrl
    entrySummary
    entryTheme {
      ...entryTheme
    }
    clientCategory {
      ...clientCategory
    }
    serviceCategory {
      ...serviceCategory
    }
  }
`);

const CaseStudyEntryCard = withFragmentArray(
  CASE_STUDY_CARD_FRAGMENT,
  (data, props: Omit<CaseStudyCardProps, 'data'>) => {
    const item = firstNonNullable(data);

    if (!item) return null;

    return <CaseStudyCard data={item} {...props} />;
  }
);

export const CaseStudyListCard = withFragmentArray(CASE_STUDY_CARD_FRAGMENT, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return (
    <ListCardRoot
      item={{
        title: item.pageTitle,
        href: parseUri(item.uri),
      }}
    />
  );
});

export default CaseStudyEntryCard;
