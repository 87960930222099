import { BlockThemeProps } from '@/lib/parse/theme';
import { vars } from '@/styles';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { toStringOrNull } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import React, { forwardRef } from 'react';
import Box, { BoxProps } from '../ui/Box';
import * as styles from './BlockTheme.css';

type ThemeProps<C extends React.ElementType = 'section'> = BoxProps<
  C,
  {
    theme?: Partial<BlockThemeProps>;
  }
>;

const BlockTheme = forwardRef<HTMLElement, ThemeProps<'section'>>(
  ({ children, theme, ...props }, ref) => {
    const customBg = toStringOrNull(theme?.customBlockColor);

    if (!theme) return <>{children}</>;

    const bg = customBg ?? (theme.blockColor ? vars.palette.colors[theme.blockColor] : '');

    return (
      <Box
        as="section"
        ref={ref}
        textColor={theme.blockTextColor}
        style={{
          ...assignInlineVars({
            [styles.bg]: bg,
          }),
        }}
        {...mergePropsClassName(props, styles.root)}>
        {children}
      </Box>
    );
  }
);

BlockTheme.displayName = 'BlockTheme';

export default BlockTheme;
