'use client';

import { withFragment } from '@liquorice/gql-utils';
import Card, { CardProps } from '../Card';
import { ENTRY_CARDS_FRAGMENT } from '../EntryCard/ENTRY_CARDS_FRAGMENT';
import { ArticleListCard } from '../EntryCards/ArticleCard';
import { ArticleIndexListCard } from '../EntryCards/ArticleIndexCard';
import { CaseStudyListCard } from '../EntryCards/CaseStudyCard';
import { CaseStudyIndexListCard } from '../EntryCards/CaseStudyIndexCard';
import { HomeListCard } from '../EntryCards/HomeCard';
import { OverviewListCard } from '../EntryCards/OverviewCard';
import { ServiceListCard } from '../EntryCards/ServiceCard';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './ListCard.css';

export const ListCardRoot = ({ item }: CardProps) => {
  return (
    <Card elevation={false} className={styles.root} item={item}>
      <Flex direction="row" justifyContent="between" alignItems="center" cx={{ pY: 'xs' }}>
        <Card.Cta
          size="large"
          variant="text"
          fullWidth
          underline="none"
          justify="between"
          endIcon="arrowRight"
          animateIcon
          iconBorder
          animateDirection="right">
          <Txt variant="h2" noMargin className={styles.linkText}>
            {item.title}
          </Txt>
        </Card.Cta>
      </Flex>
    </Card>
  );
};

const ListCard = withFragment(ENTRY_CARDS_FRAGMENT, (data) => {
  if (!data) return null;

  if (data.__typename === 'article_Entry') {
    return <ArticleListCard data={data} />;
  }
  if (data.__typename === 'articleIndex_Entry') {
    return <ArticleIndexListCard data={data} />;
  }
  if (data.__typename === 'caseStudy_Entry') {
    return <CaseStudyListCard data={data} />;
  }
  if (data.__typename === 'caseStudyIndex_Entry') {
    return <CaseStudyIndexListCard data={data} />;
  }
  if (data.__typename === 'home_Entry') {
    return <HomeListCard data={data} />;
  }
  if (data.__typename === 'overview_Entry') {
    return <OverviewListCard data={data} />;
  }
  if (data.__typename === 'service_Entry') {
    return <ServiceListCard data={data} />;
  }
});

export default ListCard;
