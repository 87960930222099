import { getFragmentData } from '@/graphql/__generated__';
import { CallToActionFragment } from '@/graphql/__generated__/graphql';
import {
  CALL_TO_ACTION_CUSTOM_FRAGMENT,
  CALL_TO_ACTION_EMAIL_FRAGMENT,
  CALL_TO_ACTION_ENTRY_FRAGMENT,
  CALL_TO_ACTION_FRAGMENT,
  CALL_TO_ACTION_PHONE_FRAGMENT,
  CALL_TO_ACTION_URL_FRAGMENT,
} from '@/graphql/fragments/callToAction';
import { deleteUndefined, toString, toStringOrNull } from '@liquorice/allsorts-craftcms-nextjs';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { firstNonNullable } from '@liquorice/utils';

export interface CallToAction extends Pick<IntrinsicProps<'a'>, 'target' | 'title'> {
  href: string;
  text?: string;
  children?: string;
  download?: boolean;
  //   file?: DocumentAsset;
}

const parseLink = (data: CallToActionFragment): CallToAction | null => {
  const item = firstNonNullable(data);

  let fragment = null;

  if (item?.__typename === 'callToAction_Url_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_URL_FRAGMENT, item);
  if (item?.__typename === 'callToAction_Entry_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_ENTRY_FRAGMENT, item);
  if (item?.__typename === 'callToAction_Email_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_EMAIL_FRAGMENT, item);
  if (item?.__typename === 'callToAction_Phone_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_PHONE_FRAGMENT, item);
  if (item?.__typename === 'callToAction_Custom_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_CUSTOM_FRAGMENT, item);

  if (!fragment) return null;

  const { target, type, text, title, url: href } = fragment;

  if (!href) return null;

  const download = type === 'asset';
  const defaultTarget =
    type && ['asset', 'mail', 'url', 'tel'].includes(type) ? '_blank' : undefined;

  const field: CallToAction = {
    href: toString(href),
    target: toStringOrNull(target) ?? defaultTarget,
    children: toStringOrNull(text) ?? undefined,
    title: toStringOrNull(title) ?? undefined,
    download,
  };

  return deleteUndefined(field);
};

export const parseCallToAction = createFragmentArrayParser(CALL_TO_ACTION_FRAGMENT, (data) => {
  if (!data) return null;

  const item = firstNonNullable(data);

  if (!item) return null;

  return parseLink(item);
});
